let baseURL = 'http://127.0.0.1:8000/api'
// let baseURL = 'https://core.dandanku.com/api'
let dummypic =
  'https://res.cloudinary.com/belicharcom/image/upload/v1531557474/man_mtyp4x.png'
let dummypic2 =
  'https://res.cloudinary.com/belicharcom/image/upload/v1585491469/woman_swvaj5.png'
let dummyplaceholder = 'https://via.placeholder.com/300.jpg?text=No+Image'
let host = `http://${window.location.host}`

export { baseURL, host, dummypic, dummypic2, dummyplaceholder }
